import {
  // eslint-disable-next-line no-restricted-imports
  useNavigation as useRemixNavigation,
} from "@remix-run/react";
import usePrevious from "./usePrevious";

export default function useNavigation() {
  const navigation = useRemixNavigation();
  const previousNavigationState = usePrevious(navigation.state);

  const isActionSubmission = navigation.state === "submitting";
  const isActionReload =
    navigation.state === "loading" &&
    navigation.formMethod != null &&
    navigation.formMethod === "POST";

  const submitted =
    (previousNavigationState === "loading" || previousNavigationState === "submitting") &&
    navigation.state === "idle";
  const submitting = isActionSubmission || isActionReload;

  return {
    navigation,
    submitted,
    loaded: submitted,
    loading: navigation.state === "loading",
    submitting,
    idle: navigation.state === "idle",
    location: navigation.location,
    state: navigation.state,
  };
}
