import type { UIMatch } from "@remix-run/react";
import { useLocation, useMatches, useNavigate } from "@remix-run/react";
import type { TenantRoleType, WhoAmI } from "@tamarack/sdk";
import useFetcherShared from "@tamarack/shared/hooks/useFetcher";
import useNavigationShared from "@tamarack/shared/hooks/useNavigation";
import type { RefObject } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

/**
 * Re-export these from shared because they are used all over the app right now. We will
 * incrementally move these over.
 *
 * The original functions were moved to the shared workspace
 */
export const useFetcherSubmitted = useFetcherShared;
export const useNavigation = useNavigationShared;

export const useUserRoles = () => {
  const matches = useMatches() as UIMatch<{ whoAmI: WhoAmI }>[];
  return matches.find((match) => match.data?.whoAmI)?.data?.whoAmI?.roles;
};

export const useHasRequiredRole = (requiredRoles: TenantRoleType[]) => {
  const userRoles = useUserRoles();
  return requiredRoles.length === 0 || requiredRoles.some((r) => userRoles?.includes(r));
};

export function useSequentialIds() {
  var nextId = useRef(0);
  return () => {
    nextId.current++;
    return nextId.current;
  };
}

export const useIsMount = () => {
  const isMountRef = useRef(true);
  useEffect(() => {
    isMountRef.current = false;
  }, []);
  return isMountRef.current;
};

// use this to only perform things in the browser and not on the server
// This is an issue when dealing with portals and with localStorage, which are both browser
// only (for reasons)
//
// See this for a good explanation https://www.joshwcomeau.com/react/the-perils-of-rehydration/#abstractions
export const useClientOnly = () => {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  return hasMounted;
};

export const useOrderedList = (initalAmount = 0) => {
  const idFn = useSequentialIds();

  const initialArray = [...new Array(initalAmount)].map(() => {
    return { id: idFn() };
  });

  const [list, setList] = useState(new Set(initialArray));

  // Memoized to ensure we maintain reference for better component caching in React
  const value = useMemo(() => {
    return Array.from(list);
  }, [list]);

  const add = () => {
    const newEntry = { id: idFn() };
    const newSet = new Set(list.add(newEntry));
    setList(newSet);

    return newEntry;
  };

  // Uses object reference to remove from Set
  const remove = (reference: { id: number }) => {
    list.delete(reference);
    const newSet = new Set(list);
    setList(newSet);
  };

  return { value, add, remove };
};

export const useStickyClass = (stickyClass: string) => {
  const ref = useRef(null);

  useEffect(() => {
    const element = ref.current;

    const observer = new IntersectionObserver(
      ([e]) => e.target.classList.toggle(stickyClass, e.intersectionRatio < 1),
      { threshold: [1] }
    );

    if (element) {
      observer.observe(element);
    }

    return () => {
      if (element) {
        observer.unobserve(element);
      }
    };
  }, [stickyClass]);

  return ref;
};

export const useIndexIterator = (
  total: number,
  initialIndex = 0,
  onSelect?: (index: number) => void
) => {
  const [index, setIndex] = useState<number | undefined>();

  const increment = useCallback(() => {
    if (index === undefined) {
      setIndex(initialIndex);
    } else {
      setIndex(index + 1 < total ? index + 1 : 0);
    }
  }, [index, initialIndex, total]);

  const decrement = useCallback(() => {
    if (index === undefined) {
      setIndex(total - 1);
    } else {
      setIndex(index - 1 >= 0 ? index - 1 : total - 1);
    }
  }, [index, total]);

  const handleKeyUp = useCallback(
    (e: KeyboardEvent) => {
      switch (e.key) {
        case "ArrowUp":
          decrement();
          return;

        case "ArrowDown":
          increment();
          return;

        case "Enter":
        case "Space":
          if (index !== undefined) {
            onSelect?.(index);
          }

          return;

        case "Escape":
          return setIndex(undefined);
      }
    },
    [decrement, increment, index, onSelect]
  );

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [handleKeyUp]);

  return { selectedIndex: index, setSelectedIndex: setIndex };
};

export const useElementSize = () => {
  const ref = useRef<HTMLElement>(null);
  const [width, setWidth] = useState(ref.current?.getBoundingClientRect().width ?? 0);
  const [height, setHeight] = useState(ref.current?.getBoundingClientRect().height ?? 0);

  useEffect(() => {
    if (ref.current) {
      const observer = new ResizeObserver((entries) => {
        const element = entries[0];

        setWidth(element.contentRect.width);
        setHeight(element.contentRect.height);
      });

      observer.observe(ref.current);

      return () => observer.disconnect();
    }
  }, []);

  return { elementRef: ref, width, height };
};

export const useMatchesBreakpoint = (size: string, defaultMatches = true) => {
  const [matches, setMatches] = useState(defaultMatches);

  const handleMediaQueryChange = (mql: MediaQueryList) => {
    setMatches(mql.matches);
  };

  useEffect(() => {
    const mql = window.matchMedia(`(max-width: ${size})`);
    // @ts-expect-error - type mismatch is a TypeScript issue. addEventListener is wrong here
    mql.addEventListener("change", handleMediaQueryChange);

    // Set initial size comparison
    setMatches(mql.matches);

    return () => {
      // @ts-expect-error - type mismatch is a TypeScript issue. addEventListener is wrong here
      mql.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return matches;
};

export const useEscapeKeyIsBackButton = (url?: string) => {
  const navigate = useNavigate();

  useEffect(() => {
    const handleKeyUp = (e: KeyboardEvent) => {
      if (e.key === "Escape") {
        // If we have a URL, navigate to it. Otherwise, go back. Written this way to
        // make types happy
        url ? navigate(url) : navigate(-1);
      }
    };

    document.addEventListener("keyup", handleKeyUp);

    return () => {
      document.removeEventListener("keyup", handleKeyUp);
    };
  }, [url]);
};

export const useEscapeKey = (callback: () => void) => {
  useEffect(() => {
    function handleKeyPress(e: KeyboardEvent) {
      if (e.key === "Escape") {
        callback();
      }
    }
    document.addEventListener("keypress", handleKeyPress);

    return () => {
      document.removeEventListener("keypress", handleKeyPress);
    };
  }, []);
};

export const useTimeout = (time: number, callback: () => void) => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  // Delay the showing of the badge
  useEffect(() => {
    timeoutRef.current = setTimeout(callback, time);

    return () => {
      clearTimeout(timeoutRef.current);
    };
  }, [time]);
};

export function useIsStuck(ref: RefObject<HTMLDivElement>) {
  const frameRef = useRef<ReturnType<typeof requestAnimationFrame>>();
  const [stuck, setStuck] = useState(false);

  useEffect(() => {
    const handleFrame = () => {
      setStuck((ref.current?.offsetTop ?? 0) > 0);

      frameRef.current = requestAnimationFrame(handleFrame);
    };

    frameRef.current = requestAnimationFrame(handleFrame);
    return () => {
      if (frameRef.current) {
        cancelAnimationFrame(frameRef.current);
      }
    };
  }, []);

  return stuck;
}

export function useTableIsLoading() {
  const location = useLocation();
  const navigation = useNavigation();

  return (
    location.pathname === navigation.location?.pathname &&
    location.search !== navigation.location?.search &&
    navigation.loading
  );
}
